import React, { useContext, useEffect, useState } from 'react';
import uniqid from 'uniqid';
import { useInView } from 'react-intersection-observer';
import { getImageUrl } from '@src/utils/get-image-url';
import getClassName from '@src/utils/get-class-name';
import { Image } from '@src/interfaces';
import { somMarketingDefault, SomMarketingValues } from '@src/utils/somtag';
import { AdsContext } from '@src/context/ads';
import CumulusContentResource from '@src/components/OasisPlayer/cumulus-content-resource';
import { ContentResource } from '@p7s1/oasis-types';
import getCmp from '@src/utils/cmp';
import { OasisPlayerStoreContext } from './OasisPlayerStore';

interface OasisPlayerProps {
  image?: Image;
  video: CumulusContentResource;
  isActive?: boolean;
  url?: string;
}

const OasisPlayer = ({ image, video, isActive = false }: OasisPlayerProps) => {
  const uniqueId = React.useMemo(() => uniqid(), []); // use or generate persiting unique id
  const id = (video && video.id) || uniqueId;
  const [targetRef, isInViewport] = useInView({ delay: 150 });
  const [cmpLoaded, setCmpLoaded] = useState(false);
  const { state, dispatch } = useContext(OasisPlayerStoreContext);

  const { adsEnabled } = useContext(AdsContext);

  function getVideoWithSomtag(): ContentResource {
    const somMarketingValues: SomMarketingValues = {
      ...somMarketingDefault,
      ...{
        video: {
          longplay: video.duration ? video.duration > 8 * 60 : false,
          blocks: {
            pre: { enabled: adsEnabled, slots: {} },
            mid: { enabled: adsEnabled, slots: {} },
            post: { enabled: adsEnabled, slots: {} },
          },
        },
        taxonomy: {
          channels: video.taxonomy ?? ['other', 'other'],
          content: 'content',
          topics: {},
        },
      },
    };

    return {
      ...(video as ContentResource),
      ...{ somMarketing: somMarketingValues },
      ...{},
    };
  }

  const setActive = () => {
    dispatch({
      type: 'update',
      state: {
        activePlayer: id,
        contentResource: getVideoWithSomtag(),
        isInView: true,
        routeChangesSurvived: 0,
      },
      routeChanged: false,
    });
  };

  const isConsentBannerLoaded = async () => {
    const cmp = await getCmp();
    return cmp?.isInitialized() ?? false;
  };

  useEffect(() => {
    isConsentBannerLoaded().then(isLoaded => {
      setCmpLoaded(isLoaded);
    });
  });

  // on init
  useEffect(() => {
    if (cmpLoaded && isActive) {
      if (!state.activePlayer) {
        setActive();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, cmpLoaded]);

  useEffect(() => {
    if (isInViewport !== null && state.isInView !== isInViewport && state.activePlayer === id) {
      dispatch({ type: 'update', state: { isInView: isInViewport, routeChanged: false}, routeChanged: false });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isInViewport]);

  const onClickImage = () => setActive();

  const backgroundUrl = image
    ? getImageUrl(image.url, 'xlarge')
    : '/assets/background-bright-with-logo-v2.png';

  return (
    <div
      ref={targetRef}
      className={getClassName([
        'oasis-player',
        [!!video?.contentType, 'content-type-', `${video?.contentType}`],
      ])}
    >
      <div
        className="video-frame"
        id={`video-frame-${id}`}
        onClick={onClickImage}
        style={{ backgroundImage: `url(${backgroundUrl})` }}
      />
    </div>
  );
};

export default OasisPlayer;
